const initialState = () => ({

})

const STATE = initialState()

const GETTERS = {
}

const ACTIONS = {
}

const MUTATIONS = {}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
